import React from 'react';
import { TextAnime } from './animations/TextAnime';
import { HashLink as Link } from 'react-router-hash-link';
import { motion } from 'framer-motion';

export const Content = () => {
  return (
    <div className='w-screen flex relative opacity-100 mt-44 mb-44 lg:mt-28 lg:mb-12 px-5' id='about'>
        <div className='relative p-3 lg:p-10 pt-10'>
            <h1 aria-label=" Hi,  I’m TJ,  web developer" className='lg:text-8xl text-4xl font-black leading-25 block relative'>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>H</span>
            <span aria-hidden="true" className='inline lg:inline-block ease-out opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>i</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>,</span><br></br>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>I</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>'</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>m</span>&nbsp;
            <TextAnime />
            {/* <span aria-hidden="true" className='inline-block opacity-100 hover:text-[#08fdd8] hover:animate-pi'>T</span>
            <span aria-hidden="true" className='inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>J</span> */}
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>, </span><br></br>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>w</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>b</span>&nbsp;   
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>d</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>v</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>l</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>o</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>p</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>r</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>!</span>
            </h1>
            <motion.p className='text-xl tracking-widest mt-8 text-gray-400 hover:text-[#08fdd8]'
              initial={{ x:'-100vw'}}
              animate={{ x: 0}}
              transition={{type: 'spring', duration: 3, bounce: 0.3, delay: 1}}>Front End Developer</motion.p><br></br>
            <motion.p className='text-xl tracking-widest mt-4 mb-8 mr-2 lg:mr-0 text-gray-400 hover:text-[#08fdd8]'
              initial={{ x:'-100vw'}}
              animate={{ x: 0}}
              transition={{type: 'spring', duration: 3, bounce: 0.3, delay: 1.5}}
            >I bring ideas to reality and develop effective,captivating and responsive websites </motion.p><br></br>
            <motion.div initial={{ x:'-100vw'}}
              animate={{ x: 0}}
              transition={{type: 'spring', duration: 3, bounce: 0.3, delay: 2}}>
             <Link to='#contact' smooth className='text-[#08fdd8] text-xl px-8 py-4 justify-center text-center bg-[#1d1d1d] border-solid rounded-md border-4 border-[#08fdd8] hover:bg-gradient-to-r from-[#33ccff] to-[#08fdd8] hover:text-[#1d1d1d] opacity-100 mb-8'>Contact me!</Link>
            </motion.div>
        </div>
    </div>
  )
}
