import React, { useRef } from 'react';
import emailjs  from '@emailjs/browser';
// import { TiContacts } from 'react-icons/ti';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';


export const Content4 = () => {
    const form = useRef();

    const {ref, inView} = useInView();
    const animation = useAnimation();

    useEffect(() => {
      console.log('use effect hook, inView = ', inView);
      if(inView){
        animation.start({
          x: 0,
          transition: {
            type: 'spring', duration: 3, bounce: 0.3, delay: 1
          }
        })
      }
      if(!inView){
        animation.start({x: '100vw'})
      }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ndmgtaj', 'template_z4fozk1', form.current, 'B9MRGWd47gnhHxvKW')
        .then((result) => {
            console.log(result.text);
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    };

    const AnimatedLetters = ({title}) => (
        <motion.span className='flex flex-row' variants={wrapper} initial='initial' animate='animate'>
          {[...title].map((letter) => (
            <motion.span className='flex lg:text-5xl text-3xl font-bold uppercase text-[#08fdd8] tracking-widest' variants={letterAnimation}>{letter}</motion.span>
          ))}
        </motion.span>
      );
    
      const wrapper = {
        animate: { 
          transition: {
            delayChildren: 0.4,
            staggerChildren: 0.4,
        },},
      };
    
      const letterAnimation = {
        initial: {
            // opacity: 0,
          },
          animate: {
            opacity: 1,
            rotate: 360,
            transition: {
              // ease:[0.6, 0.01, -0.05, 0.95],
              // ease: 'circInOut',
              type: 'spring',
              duration: 2,
              bounce: 0.4,
            }
          }
      }

    

  return (
    <div className='overflow-hidden lg:flex items-center relative transition-opacity delay-400 mt-20 lg:mt-0 p-5' id='contact'>
        <div className='absolute -z-1 text-[60rem] text-[#2b2b2b] left-1/4 font-semibold'>@</div>
        <div className='relative flex flex-col lg:items-center lg:justify-center' ref={ref}>
            <motion.header className='flex flex-row space-x-4 mb-2 pt-8' variants={wrapper}>
                <AnimatedLetters title={'contact'}/>
                <AnimatedLetters title={'me'}/>
                {/* <TiContacts size={70} color='#08fdd8' className='z-0'/> */}
            </motion.header>
            <p className='relative mb-5 text-xl tracking-widest lg:p-8 lg:pr-4 text-gray-400'>I'm intersted in front end freelance opportunities and projects that improve my skills. However, if you have other request or question, don't hesistate to use the form.</p>
            <motion.div className='pt-[1rem] block relative border-2 border-solid rounded-lg border-[#08fdd8] shadow-lg shadow-gray-300/50' animate={animation}>
                    <form className='relative ' ref={form} onSubmit={sendEmail}>
                        <ul className='p-4'>
                            <li className='overflow-hidden inline-block relative w-[90%] border-[#08fdd8] border-2'>
                                <input className='w-full b-0 h-[45px] text-[16px] border-box px-[20px] bg-[#2b2b2b] text-[#8d8d8d]' text='text' placeholder='Name' name='user_name'></input>
                            </li>
                            <li className='overflow-hidden inline-block relative w-[90%] border-[#08fdd8] border-2'>
                                <input className='w-full b-0 h-[45px] text-[16px] border-box px-[20px] bg-[#2b2b2b] text-[#8d8d8d]'type='email' placeholder='Email' name='user_email'></input>
                            </li>
                            <li className='overflow-hidden block relative mt-[5px] w-[90%] border-[#08fdd8] border-2'>
                                <input className='w-full b-0 h-[30px] text-[16px] border-box px-[20px] bg-[#2b2b2b] text-[#8d8d8d]' type='text' placeholder='Subject' name='Subject'></input>
                            </li>
                            <li className='overflow-hidden block relative mt-[10px] w-[90%] border-[#08fdd8] border-2'>
                                <textarea className='w-full b-0 h-[75px] text-[16px] border-box px-[20px] bg-[#2b2b2b] text-[#8d8d8d]' placeholder='Message' name='message'>
                                </textarea>
                            </li>
                            <li className='overflow-hidden block relative my-[5px] w-[90%]'>
                                 <input type='submit' value='Send' className='text-[#08fdd8] text-xl px-12 py-4 justify-center text-center bg-[#2b2b2b] rounded-md hover:bg-gradient-to-r from-[#33ccff] to-[#08fdd8] hover:text-[#2b2b2b] opacity-100' />
                            </li>
                        </ul>
                    </form>
                </motion.div>
        </div>
        {/* <div className='lg:absolute relative lg:inline-block block lg:left-3/4 lg:mt-[-70px] mt-10 font-semibold lg:pl-5'>
            <p className='text-3xl ml-5 leading-6 cursor-pointer block ease-out text-[#08fdd8]'>Contacts</p>
            <a href='https://twitter.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer block mt-[45px] ease-out text-[#08fdd8]'><h3>Twitter</h3></a>
            <a href='https://github.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer block mt-[40px] ease-out text-[#08fdd8]'><h3>GitHub</h3></a>
            <a href='https://www.linkedin.com/in/tofunmi-tijani-1a796024a/' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer block mt-[40px] ease-out text-[#08fdd8]'><h3>Linkdln</h3></a>
        </div> */}
    </div>
  )
}
