import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { CgTwitter } from 'react-icons/cg';
import { BsGithub, BsWhatsapp } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { AiOutlineMenu }from 'react-icons/ai';
import { CgLaptop }from 'react-icons/cg';
import { motion } from 'framer-motion';


export const NavBar = () => {
    const[toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className='relative'>
      <div className='p-5 lg:pl-16 pb-6 flex justify-between items-center w-screen fixed h-[60px] bg-[#4d5059b3] z-50'>
          <motion.div className='flex flex-row space-x-4 items-center justify-center'
            initial={{ x:'-100vw'}}
            animate={{ x: 0}}
            transition={{type: 'spring', duration: 2, bounce: 0.3, delay: 0.8}}
            >
            <CgLaptop className='h-10 lg:h-16 w-10 lg:w-16 text-center' color='#08fdd8'/>
            <h1 className="lg:font-bold lg:text-2xl text-xl font-light text-transparent bg-clip-text bg-gradient-to-r from-[#33ccff] to-[#08fdd8] items-center">
            TIJANI  
            </h1>
          </motion.div>
         <div>
              <div className='hidden lg:flex lg:justify-center lg:items-center'>
                <Link to='#about' smooth><p className='text-xl ml-5 leading-6 cursor-pointer ease-out text-gray-400 hover:text-[#08fdd8]'>About</p></Link> 
                <Link to='#skills' smooth><p className='text-xl ml-5 leading-6 cursor-pointer ease-out  text-gray-400 hover:text-[#08fdd8]'>My Skills</p></Link>
                <Link to='#work' smooth><p className='text-xl ml-5 leading-6 cursor-pointer ease-out  text-gray-400 hover:text-[#08fdd8]'>Work</p></Link>
                <Link to='#contact' smooth><p className='text-xl ml-5 leading-6 cursor-pointer ease-out  text-gray-400 hover:text-[#08fdd8]'>Contact</p></Link>
                <a href='https://github.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer ease-out text-gray-400'><BsGithub /></a>
                <a href='https://www.linkedin.com/in/tofunmi-tijani-1a796024a/' target='_blank' rel='noreferrer' className='m-8 text-gray-400'><BsLinkedin /></a>
                <a href='https://wa.me/2348102684993' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-gray-400'><BsWhatsapp /></a>
                <a href='https://twitter.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer ease-out text-gray-400'><CgTwitter /></a>
              </div>
              <div>
                <AiOutlineMenu className='ml-[1rem] lg:hidden' color='#08fdd8' size={26} onClick = {() => {setToggleMenu(true)}}/>
              </div>
         </div>

          {
              toggleMenu && (
                <motion.div className='flex items-center flex-col text-start z-20 justify-start bg-[#1d1d1d] p-8 mt-2 top-12 right-0 fixed w-screen bottom-0 lg:hidden h-fit' initial={{ x:'100vw'}}
                animate={{ x: 0}} transition={{type: 'spring', duration: 2, bounce: 0.3}}>
                  <hr className='h-[2px] bg-[#08fdd8] w-full my-2'></hr>
                  <Link to='#about' smooth onClick = {() => {setToggleMenu(false)}}><p className='p-2 text-gray-400'>About</p></Link>
                  <hr className='h-[2px] bg-[#08fdd8] w-full my-2'></hr>
                  <Link to='#skills' smooth onClick = {() => {setToggleMenu(false)}}><p className='p-2 text-gray-400'>My Skills</p></Link>
                  <hr className='h-[2px] bg-[#08fdd8] w-full my-2'></hr>
                  <Link to='#work' smooth onClick = {() => {setToggleMenu(false)}}><p className='p-2 text-gray-400'>Work</p></Link>
                  <hr className='h-[2px] bg-[#08fdd8] w-full my-2'></hr>
                  <Link to='#contact' smooth onClick = {() => {setToggleMenu(false)}}><p className='p-2 text-gray-400'>Contact</p></Link>
                  <hr className='h-[2px] bg-[#08fdd8] w-full my-2'></hr>
                  <div className='flex flex-row space-x-4 mt-2'>
                    <a href='https://github.com/Thieejhay' target='_blank' rel='noreferrer' className='text-gray-400'><BsGithub /></a>
                    <a href='https://www.linkedin.com/in/tofunmi-tijani-1a796024a/' target='_blank' rel='noreferrer' className='text-gray-400'><BsLinkedin /></a>
                    <a href='https://wa.me/2348102684993' target='_blank' rel='noreferrer' className='text-gray-400'><BsWhatsapp /></a>
                    <a href='https://twitter.com/Thieejhay' target='_blank' rel='noreferrer' className='text-gray-400'><CgTwitter /></a>
                  </div>
                </motion.div>
              )
            }
      </div>
    </div>
  )
}








