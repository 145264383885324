import React from 'react'
import { CardAnime } from './animations/CardAnime';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';


export const Content3 = () => {

    const {ref, inView} = useInView();
    const animation = useAnimation();

    const AnimatedLetters = ({title}) => (
        <motion.span className='flex flex-row' variants={wrapper} initial='initial' animate='animate'>
          {[...title].map((letter) => (
            <motion.span className='flex lg:text-5xl text-2xl font-bold uppercase text-[#08fdd8] tracking-widest' variants={letterAnimation}>{letter}</motion.span>
          ))}
        </motion.span>
      );
    
      useEffect(() => {
        console.log('use effect hook, inView = ', inView);
        if(inView){
          animation.start({
            rotateX: 360,
            transition: {
             duration: 1.5, delay: 0.5
            }
          })
        }
        if(!inView){
          animation.start({rotateX: 180})
        }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inView]);
    

      const wrapper = {
        animate: { 
          transition: {
            delayChildren: 0.4,
            staggerChildren: 0.4,
        },},
      };
    
      const letterAnimation = {
        initial: {
            // opacity: 0,
          },
          animate: {
            opacity: 1,
            rotate: 360,
            transition: {
              // ease:[0.6, 0.01, -0.05, 0.95],
              // ease: 'circInOut',
              type: 'spring',
              duration: 2,
              bounce: 0.4,
            }
          }
      }

  return (
    <div className='overflow-hidden lg:pl-8 p-5 relative transition-opacity delay-400 opacity-100' id='skills'>
        <div ref={ref}>
            <motion.header className='flex flex-row space-x-4 lg:justify-center lg:items-center' variants={wrapper}>
                    <AnimatedLetters title={'me'}/>
                    <AnimatedLetters title={'myself'}/>
                    <AnimatedLetters title={'and'}/>
                    <AnimatedLetters title={'I'}/>
            </motion.header>
        </div>
       <div className='flex lg:flex-row flex-col'>
        <div className='basis3/5 lg:p-8'>
            <p className='relative mb-8 text-lg tracking-widest text-gray-400 mt-4'>
                Focused and Dedicated Frontend developer with the eagerness to learn and improve on set skills given the opportunity. Dependable and quick-learning team player with effective communication and organization skills.
            </p>
            <p className='relative text-lg tracking-widest text-gray-400'>
                Completely willing to take on tasks in order to reach set goals and also gain additional knowledge and skills required of me in order to progress and improve daily.
            </p>
        </div>
        <div className='flex basis-2/5 mt-8' ref={ref}>
            <motion.div animate={animation}>
                <CardAnime />
            </motion.div>
        </div>
       </div>
    </div>
  )
}
