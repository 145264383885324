import React from 'react'
import { Cards } from './Cards'
// import Data from './Data'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import img1  from '../img/img1.png';
import img2 from '../img/img2.png';
import img4 from '../img/img4.png'
// import { HiBriefcase }from 'react-icons/hi';




export const Content2 = () => {

  const {ref, inView} = useInView();
  const animation = useAnimation();
  const animations = useAnimation();
  const animationss = useAnimation();

  useEffect(() => {
    console.log('use effect hook, inView = ', inView);
    if(inView){
      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 3, bounce: 0.3
        }
      })
    }
    if(!inView){
      animation.start({x: '-100vw'})
    }
    if(inView){
      animations.start({
        x: 0,
        transition: {
          type: 'spring', duration: 3, bounce: 0.3, delay: 1
        }
      })
    }
    if(!inView){
      animations.start({x: '100vw'})
    }
    if(inView){
      animationss.start({
        x: 0,
        transition: {
          type: 'spring', duration: 3, bounce: 0.3, delay: 1.5
        }
      })
    }
    if(!inView){
      animationss.start({x: '-100vw'})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const AnimatedLetters = ({title}) => (
    <motion.span className='flex flex-row' variants={wrapper} initial='initial' animate='animate'>
      {[...title].map((letter) => (
        <motion.span className='flex lg:text-5xl text-3xl font-bold uppercase text-[#08fdd8] tracking-widest' variants={letterAnimation}>{letter}</motion.span>
      ))}
    </motion.span>
  );

  const wrapper = {
    animate: { 
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.2,
    },},
  };

  const letterAnimation = {
    initial: {
      // opacity: 0,
    },
    animate: {
      opacity: 1,
      rotate: 360,
      transition: {
        // ease:[0.6, 0.01, -0.05, 0.95],
        // ease: 'circInOut',
        type: 'spring',
        duration: 2,
        bounce: 0.4,
      }
    }
  }


  return (
    <div className='p-5 min-h-0 relative transition-opacity delay-400 mt-10 opacity-100 mb-10 lg:pl-8' id='work'>
        {/* <div className='absolute -z-1 text-[30rem] text-[#2b2b2b] left-1/4 top-[5rem] font-semibold'>Work</div> */}
        <div className='flex lg:p-4 relative mb-10'>
            <div className='relative'> 
                <motion.header className='flex flex-row lg:justify-center lg:items-center' variants={wrapper}>
                    <AnimatedLetters title={'portfolio'}/>
                    {/* <HiBriefcase size={70} color='#00CCBB'/> */}
                </motion.header>
                <p className='relative mb-5 text-xl tracking-widest lg:p-8 lg:pr-4 text-gray-400'>
                A small gallery of some things i've built mainly with HTML, CSS, JavaScript, Tailwindcss, React.js, React Native and APIs. Ranging from fully functional webapps to landing pages and websites. There's more from where these came from.
                </p>
                {/* {card} */}
                <div className='flex lg:flex-row lg:justify-center lg:items-center flex-col lg:space-x-4 space-y-4 lg:space-y-0'>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://expo.dev/@thieejhay24/delivery-me'>
                      <motion.div animate={animation}>
                        <Cards id={1} title='Delivery APP' stack='React native, Tailwind css, React Redux, sanity.io' desc='A delivery project built with React Native and Sanity.io as backend. The App consit of different Restaunrants with their respective menus, you can basically order food to be deliverd to you!' src={img2}/>
                      </motion.div>
                    </a>
                  </div>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://fitfamclub.pages.dev/'>
                      <motion.div animate={animationss}>
                        <Cards id={3} title='FitFamClub' stack='React js, Tailwind css framer-motion' desc='A gym website' src={img1}/>
                      </motion.div>
                     </a>
                  </div>
                </div>
                <div className='flex lg:flex-row lg:justify-center lg:items-center flex-col mt-4'>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://google-app-phi.vercel.app/search'>
                      <motion.div animate={animations}>
                        <Cards id={2} title='Google clone' stack='React js, Tailwind css' desc='A google clone project. This site return Pulled result from the api upon search' src={img4}/>
                      </motion.div>
                     </a>
                  </div>
                </div>
                <a target='_blank' rel='noreferrer' href='https://docs.google.com/document/d/e/2PACX-1vRFppo3zLmMDpuAHdfm34m3kNG1AwmEPzTBSBamAa6jk0tAhf3VtjQaVIjdU8aL_5LILumpISs0e-BC/pub' className='text-[#08fdd8] text-xl px-8 py-4 justify-center text-center bg-[#1d1d1d] border-solid rounded-md border-4 border-[#08fdd8] hover:bg-gradient-to-r from-[#33ccff] to-[#08fdd8] hover:text-[#1d1d1d] opacity-100 absolute right-0 mt-5'>See Resume...</a>
            </div>
        </div>
    </div>
  )
}
