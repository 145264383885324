import React from 'react';
// import {FcBinoculars} from 'react-icons/fc';
// import {GoCode} from 'react-icons/go';

export const Cards = ({ title, stack, desc, live, github, id, src}) => {
  return (
    <div>
      <div className='border-2 border-solid border-[#08fdd8] rounded-md relative cursor-pointer overflow-hidden lg:w-96 h-48' key={id}>
        <img src={src} alt='' className='w-fit'/>
        <div className='absolute opacity-0 top-0 left-0 right-0 bottom-0 bg-gray-100 text-center hover:opacity-100'>
          <h2 className='text-2xl p-2 border-b-2 border-b-solid border-gray-800 opacity-100 duration-75 hover:opacity-100 text-gray-800'>{title}</h2>
          <h4 className='p-2 text-gray-800'>{stack}</h4>
          <p className='p-2 text-gray-800'>{desc}</p>
        </div>    
      </div>
    </div>
  )
}




