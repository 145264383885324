import React from 'react';
import { CgTwitter } from 'react-icons/cg';
import { BsGithub } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';

export const Footer = () => {
  return (
    <div className='text-center mt-8 justify-center'>
      <div className='flex flex-row space-x-12 justify-center items-center p-4'>
        <a href='https://github.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-gray-400'><BsGithub /></a>
        <a href='https://www.linkedin.com/in/tofunmi-tijani-1a796024a/' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-gray-400'><BsLinkedin /></a>
        <a href='https://wa.me/2348102684993ß' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-gray-400'><BsWhatsapp /></a>
        <a href='https://twitter.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-gray-400'><CgTwitter /></a>
      </div>
      <h1 className='text-[#08fdd8] lg:text-lg text-base tracking-widest p-4'>@2022 Made by Tijani.</h1>
    </div>
  )
}
