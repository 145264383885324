import React from 'react';
import styled from 'styled-components';
import { SiTailwindcss, SiTypescript, SiReact, SiRedux, SiGithub } from 'react-icons/si';
import { TbBrandNextjs, TbBrandReactNative } from 'react-icons/tb';


export const CardAnime = () => {
  return (
    <Wrapper>
        <CardWrapper>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <img alt='logo' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqbbJymblNozHKOUaTgZwVd26waUKJBZsleA&usqp=CAU' className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center'></img>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>JAVASCRIPT</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <SiReact className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#61DBFB]'/>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>REACT Js</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <SiTailwindcss className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#07B6D5]'/>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>TAILWIND CSS</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <SiTypescript className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#017ACB]'/>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>TYPESCRIPT</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <TbBrandNextjs className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#111]'/>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>Next Js</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <SiRedux className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#764ABC]'/>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>Redux</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <TbBrandReactNative className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#61DAFB]'/>   
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>React Native</p>
            </Card>
            <Card className='relavtive flex flex-row lg:space-x-4 space-x-2'>
                <SiGithub className='object-cover lg:w-12 lg:h-12 w-8 h-8 self-center fill-[#111]'/>
                <p className='text-gray-400 font-medium lg:text-xl text-base items-center leading-none self-center'>GIT</p>
            </Card>
        </CardWrapper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
display: grid;
padding: 2rem;
@media (max-width: 640px) {
    padding: 0px;

}
`
const CardWrapper = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 30px;
padding: 18px;
pointer-events: none;
border-width: 1px;
border-radius: 12px;
justify-content: center;
border-color: transparent;
box-shadow: rgba(0, 0, 0, 16.2) 0px 1px 4px;
border-style: solid;
box-sizing: border-box;
@media (max-width: 640px) {
    grid-gap: 18px;
    padding: 12px;
}
`
const Card = styled.div`
display: flex;
padding: 10px 20px 15px 20px;
cursor: pointer;
opacity: 1;
transition: all 150ms ease-in-out;
justify-self: center;
border: solid;
border-color: #08fdd8;
border-radius: 0.5rem;
@media (max-width: 640px) {
    padding: 8px;
    justify-self: center;
    transfrom: scale(0.5, 0.5)
}
`